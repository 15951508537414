<template>
  <div>
    <div class="addtask">
      <b-modal
        :id="id"
        cancel-variant="outline-secondary"
        centered
        size="lg"
        class="addtask-modal"
        @hidden="clearForm"
        @shown="getTaskDetails"
      >
        <template #modal-title>
          <h3 class="addtask-from-title">
            <span>
              {{
                taskID ? task_title : boardStatus ? boardStatus.name : " "
              }}</span>
          </h3>
        </template>
        <validation-observer
          ref="sprintTasksRules"
          v-slot="{ invalid }"
        >
          <b-row class="addtask-content">
            <!-- Select Title Block -->
            <b-col cols="12">
              <b-form-group
                label=""
                label-for="title"
                class="mt-1 to-do-input auto-resize-teaxtarea"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Task name"
                  rules="required"
                >
                  <textarea
                    ref="textarea"
                    v-model="title"
                    name="Task name"
                    placeholder="Task Name..."
                    :disabled="userInfo.role !== 'SA'"
                    @input="resizeTextarea"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Select Assignee Block -->
            <b-col
              cols="12"
              lg="6"
            >
              <b-form-group
                label="assignee"
                label-for="assignee"
                label-cols="4"
                label-cols-lg="3"
                class="required-form-filed"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Assignee"
                  rules="required"
                >
                  <v-select
                    v-model="assignee_id"
                    label="full_name"
                    name="assignee_id"
                    :options="users"
                    :reduce="(u) => u.id"
                    :disabled="userInfo && userInfo.role != 'SA'"
                    placeholder="Select Any Member"
                    class="select-size-lg select-task-option"
                  >
                    <template v-slot:option="option">
                      <b-avatar
                        size="30"
                        :src="option.picture"
                        :text="option.name.charAt(0)"
                        class="mr-1"
                      />
                      <img
                        :src="option.img"
                        alt=""
                      >
                      {{ option.name }}
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Choese Due date Block -->
            <b-col
              cols="12"
              lg="6"
            >
              <b-form-group
                label="Due date"
                label-for="due_date"
                label-cols="4"
                label-cols-lg="3"
                class="required-form-filed"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Due date"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    @click="toggleDueDate"
                  >
                    <flat-pickr
                      id="astartdate"
                      ref="dueDate"
                      v-model="due_date"
                      name="due_date"
                      placeholder="Due date"
                      class="form-control"
                      :config="configDueDate"
                      :disabled="userInfo.role !== 'SA'"
                      :style="userInfo.role !== 'SA' ? 'cursor:default' : null"
                    />
                    <b-input-group-append is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger d-block">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Select Priority  Block-->
            <b-col
              cols="12"
              lg="6"
            >
              <b-form-group
                label="priority"
                label-for="priority"
                label-cols="4"
                label-cols-lg="3"
                class="required-form-filed"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Priority"
                  rules="required"
                >
                  <v-select
                    id="priority"
                    v-model="priority"
                    label="name"
                    :options="allPriorities"
                    :disabled="userInfo && userInfo.role != 'SA'"
                    class="select-size-lg select-task-option"
                    name="priority"
                    placeholder="Select Any Priority"
                    :reduce="(p) => p.value"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- select task type -->
            <b-col
              cols="12"
              lg="6"
              class="project-select"
            >
              <b-form-group
                label="Status"
                label-for="status"
                label-cols="4"
                label-cols-lg="3"
                class="required-form-filed"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Status"
                  rules="required"
                >
                  <v-select
                    id="task_status"
                    v-model="task_status"
                    label="name"
                    :options="projectStatus"
                    :disabled="userInfo && userInfo.role != 'SA'"
                    class="select-size-lg select-task-option"
                    name="task_status"
                    placeholder="Select Any Status"
                    :reduce="(status) => status.id"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- multiple select tag  -->
            <b-col
              cols="12"
              lg="6"
            >
              <b-form-group
                label="Tag"
                label-for="tag"
                label-cols="4"
                label-cols-lg="3"
                class="required-form-filed"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tags"
                  rules="required"
                >
                  <v-select
                    id="tags"
                    v-model="tags"
                    hide-details
                    multiple
                    name="tags"
                    label="name"
                    :options="$store.state.app.tagList"
                    :disabled="userInfo && userInfo.role != 'SA'"
                    class="select-size-lg task-tag general"
                    placeholder="Select Any Tag"
                    :close-on-select="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Description Block -->
            <b-col cols="12">
              <b-form-group
                label-for="task-description"
                class="text-editer"
              >
                <div class="description">
                  <p>Description</p>
                </div>
                <b-form-textarea
                  v-if="disableNormalEditor"
                  id="title"
                  placeholder="Add a task Description"
                  name="project_name"
                  :disabled="userInfo.role !== 'SA'"
                  rows="6"
                  @click="disablededitor"
                />
                <quill-editor
                  v-if="disableQuillEditor"
                  ref="description1"
                  v-model="description"
                  :options="editorOption"
                  :disabled="userInfo.role !== 'SA'"
                  @blur="onEditorBlur()"
                >
                  <div
                    id="toolbar"
                    slot="toolbar"
                  >
                    <!-- Add font size dropdown -->
                    <select class="ql-size">
                      <option value="small" />
                      <!-- Note a missing, thus falsy value, is used to reset to default -->
                      <option selected />
                      <option value="large" />
                      <option value="huge" />
                    </select>

                    <select class="ql-font">
                      <option selected="selected" />
                      <option value="serif" />
                      <option value="monospace" />
                    </select>
                    <!-- Add a bold button -->
                    <button class="ql-bold">
                      Bold
                    </button>
                    <button class="ql-italic">
                      Italic
                    </button>
                    <button class="ql-underline">
                      Underline
                    </button>

                    <button class="ql-clean" />
                    <select class="ql-align">
                      <option selected="selected" />
                      <option value="center" />
                      <option value="right" />
                      <option value="justify" />
                    </select>
                    <button class="ql-link" />
                    <!-- You can also add your own -->
                  </div>
                </quill-editor>
              </b-form-group>
            </b-col>
            <!-- File Attachments  block-->
            <b-col cols="12">
              <fileUpload
                v-if="!getDataLoader"
                :manual-file-added="task_attachments"
                @onChange="handleFileUpload($event)"
                @onFileRemove="remove_attachments = $event"
              />
            </b-col>
          </b-row>
          <div
            v-if="userInfo && userInfo.role == 'SA'"
            class="text-right px-3 mb-2 mt-1"
          >
            <b-button
              class="addtask-start-btn"
              :disabled="loader"
              @click="validationForm"
            >
              <b-spinner
                v-if="loader"
                small
                class="spinner"
              />
              {{ taskID ? "Update" : "Create" }}
            </b-button>
          </div>
          <!-- Activity, Add Comment and View Comment Block Show -->
          <div
            v-if="taskID"
            class="feed-back"
            :class="{
              'mt-2': userInfo && userInfo.role === 'EMP'
            }"
          >
            <!-- Activity collapse Block -->
            <app-collapse accordion>
              <app-collapse-item title="Activity">
                <div class="activity">
                  <!-- <h4>Activity</h4> -->
                  <div
                    v-for="(items, index) in allActivity"
                    :key="index"
                    class="activity-content"
                  >
                    <b-avatar />
                    <div class="right-side-content">
                      <p>{{ items.description | strippedContent }}</p>
                      <!-- <p>Mayur Lalwani <span>created this task.</span></p> -->
                      <small>{{ items.updated_at | dateWithTimeFormat }}</small>
                      <!-- <small>April 19 at 3:00 PM</small> -->
                    </div>
                  </div>
                </div>
              </app-collapse-item>
            </app-collapse>
            <!-- Add Comment Block -->
            <hr v-if="allCommentsData.length">
            <div
              v-if="allCommentsData.length"
              class="feed-back2"
            >
              <app-collapse accordion>
                <app-collapse-item
                  title="Comments"
                  class="pt-25"
                >
                  <div class="activity">
                    <!-- <h4>Activity</h4> -->
                    <div class="">
                      <div
                        v-for="(items, index) in allCommentsData"
                        :key="index"
                        class="activity-content d-flex justify-content-between align-items-start"
                      >
                        <div class="d-flex">
                          <b-avatar />
                          <div class="right-side-content ml-1">
                            <p>
                              {{ items.user.name }} {{ items.user.last_name }}
                            </p>
                            <div v-html="items.comment" />
                            <small>{{ items.updated_at | dateWithTimeFormat }}
                            </small>
                          </div>
                        </div>
                        <div class="boardDropdown">
                          <b-dropdown
                            toggle-class="text-decoration-none"
                            no-caret
                          >
                            <template v-slot:button-content>
                              <button class="edit-del-button">
                                <feather-icon
                                  icon="MoreHorizontalIcon"
                                  size="21"
                                  class="align-middle edit-data-delete"
                                />
                              </button>
                            </template>
                            <b-dropdown-item class="edit-delete-item">
                              <feather-icon
                                icon="EditIcon"
                                size="16"
                                class="mr-50 edit-icons"
                              />
                              <span
                                class="edit-data"
                                @click="editComment(items.comment, items.id)"
                              >Edit Comment</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </app-collapse-item>
              </app-collapse>
            </div>
            <hr>
            <div class="comment text-editer">
              <div
                v-if="taskID"
                class="description"
              >
                <p>Add Comments</p>
              </div>
              <div class="comment-content comment-editer">
                <b-form-input
                  v-if="disableNormalComment && taskID"
                  id="basicInput"
                  placeholder="Add comment"
                  @click="disabledcomment"
                />
                <validation-provider
                  #default="{ errors }"
                  name="Comment"
                  rules="imageNotAllowed"
                >
                  <quill-editor
                    v-if="disableQuillComment && taskID"
                    v-model="comment"
                    :options="editorOption1"
                    class="flex-grow-1 overflow-hidden"
                  >
                    <div
                      id="toolbar1"
                      slot="toolbar"
                    >
                      <!-- Add font size dropdown -->
                      <select class="ql-size">
                        <option value="small" />
                        <!-- Note a missing, thus falsy value, is used to reset to default -->
                        <option selected />
                        <option value="large" />
                        <option value="huge" />
                      </select>

                      <select class="ql-font">
                        <option selected="selected" />
                        <option value="serif" />
                        <option value="monospace" />
                      </select>
                      <!-- Add a bold button -->
                      <button class="ql-bold">
                        Bold
                      </button>
                      <button class="ql-italic">
                        Italic
                      </button>
                      <button class="ql-underline">
                        Underline
                      </button>

                      <button class="ql-clean" />
                      <select class="ql-align">
                        <option selected="selected" />
                        <option value="center" />
                        <option value="right" />
                        <option value="justify" />
                      </select>
                      <button class="ql-link" />
                      <!-- You can also add your own -->
                    </div>
                  </quill-editor>
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </div>
            </div>

            <div
              v-if="taskID && disableComment"
              class="pl-3 pb-1"
            >
              <b-button
                class="addtask-start-btn comment-btn px-2 py-50 font-weight-normal"
                :disabled="commentValidation || invalid"
                @click="commentClick"
              >
                Save
                <img
                  src="../../assets/images/icons/send.svg"
                  alt="send-Icon"
                  class="mb-25 ml-25"
                >
              </b-button>
              <!-- Comments Show  -->
            </div>
          </div>
        </validation-observer>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BFormTextarea,
  BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { eventBus } from '@/main'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from 'vue-quill-editor'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vue2Dropzone from 'vue2-dropzone'
import FeatherIcon from '../../@core/components/feather-icon/FeatherIcon.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import fileUpload from '../../components/FileUpload/index.vue'

extend('imageNotAllowed', {
  validate: value => {
    // Check if the content contains any image tag
    const containsImage = /<img[^>]+src="[^"]+"[^>]*>/i.test(value)
    return !containsImage
  },
  message: 'Image is not allowed in the comment box',
})
export default {
  name: 'AddTask',
  components: {
    BButton,
    BModal,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    quillEditor,
    BAvatar,
    AppCollapse,
    AppCollapseItem,
    FeatherIcon,
    vueDropzone: vue2Dropzone,
    fileUpload,
  },
  directives: {
    'b-modal': VBModal,
  },
  filters: {
    strippedContent(string) {
      return string.replace(/<\/?[^>]+>/gi, ' ')
    },
  },

  props: {
    projectDtl: {
      // type: Object,
      required: true,
      default: null,
    },
    id: {
      type: String,
      required: false,
      default: 'addtaskdata',
    },
    boardStatus: {
      required: true,
      type: Object,
    },
    taskID: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      disableQuillEditor: false,
      disableNormalEditor: true,
      disableQuillComment: false,
      disableNormalComment: true,
      disableComment: false,
      comment: null,
      // editTask: true,
      remove_attachments: [],
      allActivity: [],
      editorOption: {
        modules: {
          toolbar: '#toolbar',
        },
        placeholder: 'Type anything',
      },
      editorOption1: {
        modules: {
          toolbar: '#toolbar1',
        },
        placeholder: 'Add a comment',
      },
      options: [
        {
          title: 'hello',
          img: require('../../assets/images/avatars/1-small.png'),
        },
      ],
      milestone: null,
      sprints: [],
      sprint_id: null,
      title: null,
      description: null,

      tasksStatus: [],
      all_tags: [],
      tags: [],
      assignee_id: [],
      reporter_id: [],
      actual_hours: null,
      priority: 'med',
      attachments: [],
      task_attachments: [],
      task_status: null,
      task_title: null,
      loader: false,
      due_date: null,
      configDueDate: {
        minDate: new Date(),
        maxDate: null,
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        clickOpens: false,
      },
      allCommentsData: [],
      users: [],
      images: [],
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}/project/task/image-store`,
        maxFiles: 20,
        uploadMultiple: true,
        parallelUploads: 100,
        thumbnailWidth: 150,
        maxFilesize: 10,
        acceptedFiles:
          '.jpeg, .jpg, .png, .txt, .pdf, .ppt,.pptx, .doc, .csv, .docx, .xls, .xlsx',
        dictDefaultMessage:
          "<b class='drop-title'>Drag & drop files</b> <br> <p class='drop-req'>Supported formats: JPEG, PNG, JPG, <br /> TXT, PDF, PPT, DOC, CSV, DOCX, XLS, XLSX <br /> ",
        addRemoveLinks: true,
      },
      pdfFile: [],
      isValidFile: true,
      FileErrorMsg: null,
      errorFileList: [],
      getDataLoader: false,
    }
  },
  computed: {
    commentValidation() {
      // const str =  this.comment ? this.comment.replace(/<p>\s*<\/p>|<p><br><\/p>|<br>/g, "") :"";
      const plainText = this.comment && this.comment.length
        ? this.comment.replace(/<[^>]+>/g, '').replace(/\s+/g, '')
        : ''
      return !(plainText.length > 0)
    },
    projectStatus() {
      return this.$store.state.app.generalBoardTaskStatus.filter(a => a.id !== 'archive')
    },
  },
  watch: {
    boardStatus(value) {
      this.task_status = value.id
    },
  },
  mounted() {
    this.userListCall()
  },
  methods: {
    async userListCall() {
      if (this.userInfo.role === 'SA') {
        if (this.$store.state.app.usersList.length === 0) {
          this.users = await this.userList()
        } else {
          this.users = this.$store.state.app.usersList
        }
      } else {
        this.users = await this.reportingAndManageByUserList()
      }
    },

    /**
     * disabled Description And open Quill Editoe
     */
    disablededitor() {
      this.disableQuillEditor = true
      this.disableNormalEditor = false
    },
    /**
     * disabled Comment And open Quill Editoe
     */
    disabledcomment() {
      this.disableQuillComment = true
      this.disableNormalComment = false
      this.disableComment = true
    },

    /**
     * Click save button And check validationForm
     */
    validationForm() {
      this.$refs.sprintTasksRules.validate().then(success => {
        if (success && this.isValidFile) {
          if (this.taskID) {
            this.updateTask()
          } else {
            this.createNewTasks()
          }
        } else {
          return false
        }
      })
    },
    /**
     * save the client form details
     */
    async createNewTasks() {
      this.loader = true
      const formData = {
        project_id: this.projectDtl.id,
        status_id: this.task_status,
        assignee: this.assignee_id,
        title: this.title,
        description: this.description,
        priority: this.priority,
        due_date: this.due_date,
        tags: this.tags.map(tag => tag.name),
        files: this.pdfFile ? await Promise.all(this.pdfFile.map(async file => ({
          name: file.name,
          file: await this.convertFileToBase64(file),
        }))) : [],
      }
      if (this.reporter_id && this.reporter_id.length) {
        formData.reporter_id = this.reporter_id
      }

      const response = await this.getHTTPPostResponse(
        'project/task/non-board-create',
        formData,
        true,
      )
      if (response && response.status == 200) {
        eventBus.$emit('generalBoardReloadCompleteTaskList', true)
        // this.$refs.sprintTasksRules.reset();
        this.$root.$emit('bv::toggle::modal', this.id)
        this.clearForm()
      }
      this.loader = false
    },
    /**
     * Edit the client form details
     */
    async getTaskDetails() {
      if (this.taskID) {
        this.loader = true
        this.getDataLoader = true
        const input = {
          project_id: this.projectDtl.id,
        }
        const response = await this.getHTTPGetResponse(
          `project/task/non-board-view/${this.taskID}`,
          input,
          false,
        )
        if (response && response.status == 200) {
          if (response.data && response.data.project_task) {
            this.loader = false
            this.allActivity = response.data.activity
            const { project_task } = response.data
            this.title = project_task.title
            this.description = project_task.description == 'null'
              ? ''
              : project_task.description
            if (this.description == '') {
              this.disableNormalEditor = true
              this.disableQuillEditor = false
            } else {
              this.disableNormalEditor = false
              this.disableQuillEditor = true
            }
            this.project = project_task.project
            this.sprint = project_task.project_plan_sprint
            this.priority = project_task.priority
            this.actual_hours = project_task.actual_hours
            this.assignee_id = project_task.assignee
            this.assignee = project_task.assignee_user
            this.reporter_id = project_task.reporter_id
            this.reporter = project_task.reporter_user
            this.task_status = project_task.status_id
            this.task_title = project_task.status.name
              .replace('_', ' ')
              .toUpperCase()
            this.task_attachments = project_task.project_task_attachments
            this.allCommentsData = project_task.project_task_comments
            if (project_task.tags && project_task.tags.length) {
              const tags = []
              project_task.tags.forEach(tag => {
                tags.push({
                  name: tag.tag,
                  id: tag.tag,
                  value: tag.tag,
                  label: tag.tag,
                })
              })
              this.tags = tags
              this.edit_task_tags = project_task.tags
            }
            this.due_date = project_task.due_date
            this.configDueDate = {
              minDate: project_task.due_date
                ? project_task.due_date
                : new Date(),
              maxDate: null,
              enableTime: true,
              dateFormat: 'Y-m-d H:i',
              clickOpens: false,
            }
            this.remove_attachments = []
            // if (this.task_attachments.length) {
            //   this.task_attachments.forEach(attachment => {
            //     const file = {
            //       size: 150,
            //       name: `${attachment.name}`,
            //       type: `${attachment.type}`,
            //       id: `${attachment.id}`,
            //     }
            //     // this.$refs.myVueDropzone.manuallyAddFile(
            //     //   file,
            //     //   `${process.env.VUE_APP_API_IMAGE_PATH}/` + attachment.url
            //     // );
            //     this.openFileInNewTab(attachment.url, false).then(
            //       response => {
            //         this.$refs.myVueDropzone.manuallyAddFile(file, response)
            //       },
            //     )
            //   })
            // }
          }
        }
        this.loader = false
        this.getDataLoader = false
      }
    },
    /**
     * Update the client form details
     */
    async updateTask() {
      this.loader = true
      const formData = {
        _method: 'PUT',
        project_id: this.projectDtl.id,
        status_id: this.task_status,
        assignee: this.assignee_id,
        title: this.title,
        priority: this.priority,
        due_date: this.due_date,
        actual_hours: this.actual_hours,
        remove_file_ids: [...this.remove_attachments],
        remove_tag_ids: this.edit_task_tags.map(tag => tag.id),
        description: this.description,
        tags: this.tags.map(tag => tag.name),
        files: this.pdfFile ? await Promise.all(this.pdfFile.map(async file => ({
          name: file.name,
          file: await this.convertFileToBase64(file),
        }))) : [],
      }
      if (this.reporter_id && this.reporter_id.length) {
        formData.reporter_id = this.reporter_id
      }

      // Conditionally add `description` if it exists
      if (this.description) {
        formData.description = this.description
      }

      const response = await this.getHTTPPostResponse(
        `project/task/non-board-update/${this.taskID}`,
        formData,
        true,
      )
      if (response && response.status == 200) {
        eventBus.$emit('generalBoardReloadCompleteTaskList', true)
        this.$refs.sprintTasksRules.reset()
        this.$root.$emit('bv::toggle::modal', this.id)
        this.clearForm()
      }
      this.loader = false
    },
    /**
     * Add comments data
     */
    async commentClick() {
      const formData = {
        project_task_id: this.taskID,
        comment: this.comment,
        project_id: this.projectDtl.name,
        comment_id: this.comment_id,
      }
      const response = await this.getHTTPPostResponse(
        'project/task/add',
        formData,
        true,
      )
      if (response && response.status == 200) {
        this.comment = null
        this.comment_id = null

        this.$root.$emit('bv::toggle::modal', this.id)
        this.clearForm()
        this.getTaskDetails()
      }
    },
    /**
     * Edit Comments Data
     */
    editComment(val, id) {
      this.disableNormalComment = false
      this.disableQuillComment = true
      this.disableComment = true
      this.comment_id = id
      this.comment = val
    },
    /**
     * Remove Attachments
     */

    removeAttachments(index, id) {
      this.remove_attachments.push(id)
      this.task_attachments.splice(index, 1)
      this.attachments.splice(index, 1)
    },
    /**
     * Clear Form
     */
    clearForm() {
      this.sprint_id = null
      this.task_status = null
      this.assignee_id = null
      this.title = null
      this.description = null
      this.priority = null
      this.reporter_id = null
      this.tags = []
      this.actual_hours = null
      this.due_date = null
      this.attachments = []
      this.task_attachments = []
      this.loader = false
      this.disableNormalEditor = true
      this.disableQuillEditor = false
      this.disableQuillComment = false
      this.disableNormalComment = true
      this.disableComment = false
      this.images = []
      this.pdfFile = []
      this.$emit('disableTaskID')
      // this.$refs.sprintTasksRules.reset();
      // this.$root.$emit("bv::toggle::modal", this.id); //this for close pop
    },
    onEditorBlur() {
      if (this.description == '') {
        this.disableNormalEditor = true
        this.disableQuillEditor = false
      }
    },
    toggleDueDate() {
      this.$refs.dueDate.fp.toggle()
    },
    handleFileUpload($event) {
      this.pdfFile = $event.file
      this.isValidFile = $event.isValidFile
    },
  },
}
</script>

<style>
@import "../../assets/scss/component-css/list.css";

.general.vs--disabled .vs__dropdown-toggle .vs__selected-options .vs__selected {
  background-color: #f97753 !important;
}
</style>
